import { appWithTranslation } from 'next-i18next';
import type { AppProps } from 'next/app';
import React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { RecoilRoot } from 'recoil';
import FelaProvider from '@bridebook/ui/src/fela-utils/FelaProvider';
import nextI18NextConfig from '../next-i18next.config';

function App({ Component, pageProps, renderer }: AppProps & { renderer: any }) {
  return (
    <RecoilRoot>
      <FelaProvider renderer={renderer}>
        <GoogleReCaptchaProvider reCaptchaKey="6LfAONIZAAAAABoCDL0XlpM6Qczyq2XzXd2A0aVY">
          <Component {...pageProps} />
        </GoogleReCaptchaProvider>
      </FelaProvider>
    </RecoilRoot>
  );
}

export default appWithTranslation(App, nextI18NextConfig);
